import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Main from './components/Main/Main';
import Result from './components/Result/Result';

const App = () => {
  return (
    <div
      style={{ backgroundColor: '#181818', minHeight: '100vh', width: '100%' }}
    >
      <Router>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/result" element={<Result />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
