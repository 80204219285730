import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Eagle from '../../images/eagle.svg';
import './Result.css';
import GenerateButton from '../Button/GenerateButton';

const Result = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const imageBlob = location.state?.imageBlob;

  const [scaledDimensions, setScaledDimensions] = useState({
    width: 0,
    height: 0,
  });

  const BOX_WIDTH = 512;
  const BOX_HEIGHT = 220;

  useEffect(() => {
    if (imageBlob) {
      const imgURL = URL.createObjectURL(imageBlob);
      const img = new Image();
      img.src = imgURL;

      img.onload = () => {
        const { width, height } = img;

        // Maintain aspect ratio for scaling
        const scaledHeightForWidth = (height / width) * BOX_WIDTH;
        if (scaledHeightForWidth <= BOX_HEIGHT) {
          // Scale dimensions based on width
          setScaledDimensions({
            width: BOX_WIDTH,
            height: scaledHeightForWidth,
          });
        } else {
          // Scale dimensions based on height
          const scaledWidthForHeight = (width / height) * BOX_HEIGHT;
          setScaledDimensions({
            width: scaledWidthForHeight,
            height: BOX_HEIGHT,
          });
        }
      };

      // Cleanup the created object URL
      return () => URL.revokeObjectURL(imgURL);
    }
  }, [imageBlob]);

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(imageBlob);
    link.download = 'swapped-image.png';
    link.click();
  };

  const handleGenerateAnother = () => {
    navigate('/');
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen relative">
      {/* Eagle Icon */}
      <img src={Eagle} alt="Eagle" className="eagle-svg" />

      {/* Main Container */}
      <div className="main-container text-center flex w-[544px] p-4 flex-col items-center gap-4 rounded bg-[#363636]">
        {/* Title */}
        <h1 className="nazi-text">You know what to do</h1>

        {/* White Line */}
        <div className="w-[512px] h-[2px] bg-white"></div>

        {/* Image Preview Box */}
        <div className="image-container flex w-[512px] h-[220px] py-[52px] px-[84px] flex-col justify-center items-center gap-[10px] rounded border border-dashed border-white bg-white/10 relative">
          {imageBlob ? (
            <img
              src={URL.createObjectURL(imageBlob)}
              alt="Generated"
              style={{
                width: `${scaledDimensions.width}px`,
                height: `${scaledDimensions.height}px`,
                objectFit: 'contain',
              }}
            />
          ) : (
            <p className="normal-text" style={{ color: '#848484' }}>
              No image available
            </p>
          )}
        </div>

        {/* Buttons */}
        <div className="flex gap-4 mt-4">
          <GenerateButton onClick={handleDownload}>
            Download
          </GenerateButton>
          <GenerateButton
            onClick={handleGenerateAnother}
            initialBgColor="#4A4A4A"
            hoverBgColor="#444444"
          >
            Generate Another One
          </GenerateButton>
        </div>
      </div>
    </div>
  );
};

export default Result;
