import React, { useState, useEffect } from 'react';
import GenerateButton from '../Button/GenerateButton';
import { useNavigate } from 'react-router-dom';
import Eagle from '../../images/eagle.svg';
import PlusIcon from '../../images/plus-2.svg';
import './Main.css';

const Main = () => {
  const [file, setFile] = useState(null); // Store the uploaded file
  const [previewFile, setPreviewFile] = useState(null); // For previewing the image
  const [scaledDimensions, setScaledDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [isUploading, setIsUploading] = useState(false); // Handle upload status
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const BOX_WIDTH = 512;
  const BOX_HEIGHT = 220;

  const handleFile = (file) => {
    const allowedExtensions = ['image/jpeg', 'image/png', 'image/webp'];
    if (file && allowedExtensions.includes(file.type)) {
      setFile(file);
      setError(null);

      // Generate preview and calculate scaled dimensions
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewFile(reader.result);

        // Dynamically calculate scaled dimensions for display
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          const { width, height } = img;

          // Maintain aspect ratio for scaling
          const scaledHeightForWidth = (height / width) * BOX_WIDTH;
          if (scaledHeightForWidth <= BOX_HEIGHT) {
            // If scaled height is within bounds, set dimensions based on width
            setScaledDimensions({
              width: BOX_WIDTH,
              height: scaledHeightForWidth,
            });
          } else {
            // Otherwise, set dimensions based on height
            const scaledWidthForHeight = (width / height) * BOX_HEIGHT;
            setScaledDimensions({
              width: scaledWidthForHeight,
              height: BOX_HEIGHT,
            });
          }
        };
      };
      reader.readAsDataURL(file);
    } else {
      setError('Please upload a valid image (JPEG, PNG, or WebP).');
    }
  };

  // File input change handler
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    handleFile(selectedFile);
  };

  // Drag-and-drop handler
  const handleDragOver = (e) => {
    e.preventDefault(); // Prevent default drag behavior
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    handleFile(droppedFile);
  };

  // Paste handler
  const handlePaste = (e) => {
    const items = e.clipboardData.items;
    for (const item of items) {
      if (item.type.startsWith('image/')) {
        const blob = item.getAsFile();
        handleFile(blob);
        break;
      }
    }
  };

  // Submit (Upload) to Backend
  const handleSubmit = async () => {
    if (!file) {
      setError('Please select, paste, or drag an image before uploading.');
      return;
    }

    setIsUploading(true);
    const formData = new FormData();
    formData.append('image', file);

    try {
      const API_URL = process.env.REACT_APP_API_URL || 'http://backend:5050';

      const response = await fetch(`${API_URL}/swap`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Failed to upload image. Status: ${response.status}`);
      }

      const blob = await response.blob(); // Get the swapped image
      const imageUrl = URL.createObjectURL(blob);
      setPreviewFile(imageUrl); // Show the result image in preview

      navigate('/result', { state: { imageBlob: blob } });
    } catch (err) {
      setError(err.message);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div
      className="flex flex-col items-center justify-center h-screen relative"
      onPaste={handlePaste}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <img src={Eagle} alt="Eagle" className="eagle-svg" />
      <div className="main-container text-center flex w-[544px] p-4 flex-col items-center gap-4 rounded bg-[#363636]">
        <h1 className="nazi-text">Swap hater's face</h1>
        <div className="w-[512px] h-[2px] bg-white"></div>

        {/* Image Preview Box */}
        {/* Image Preview Box */}
        <div className="image-container flex w-[512px] h-[220px] py-[52px] px-[84px] flex-col justify-center items-center gap-[10px] rounded border border-dashed border-white bg-white/10 relative">
          {previewFile ? (
            <>
              {isUploading && (
                <div className="reddish-overlay absolute inset-0"></div>
              )}
              {/* Display the uploaded or preview image */}
              <img
                src={previewFile}
                alt="Preview"
                style={{
                  width: `${scaledDimensions.width}px`,
                  height: `${scaledDimensions.height}px`,
                  objectFit: 'contain',
                }}
              />

              {/* Rotating Plus Icon */}
              {isUploading && (
                <div className="loading-icon absolute inset-0 flex justify-center items-center">
                  <img src={PlusIcon} alt="Loading" className="w-16 h-16" />
                </div>
              )}
            </>
          ) : (
            <>
              {/* Default SVG Placeholder */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="33"
                viewBox="0 0 32 33"
                fill="none"
              >
                <circle cx="16" cy="16.5" r="16" fill="#848484" />
                <path
                  d="M14.4727 25.5V7.5H17.5273V25.5H14.4727ZM7 18.0273V14.9727H25V18.0273H7Z"
                  fill="#303030"
                />
              </svg>
              <p
                className="normal-text"
                style={{
                  color: '#848484',
                  fontSize: '16px',
                  fontWeight: 300,
                }}
              >
                JPEG, PNG, or WebP
              </p>
            </>
          )}
        </div>

        {/* File Input Box */}
        <div className="file-input-box flex items-center gap-4 align-self-stretch border-top-bottom">
          <p className="normal-text">LOAD IMAGE</p>
          <p
            className="normal-text file-description"
            style={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 1,
              flex: '1 0 0',
              overflow: 'hidden',
              color: '#848484',
              textOverflow: 'ellipsis',
              fontSize: '16px',
              fontWeight: 300,
              textTransform: 'uppercase',
            }}
          >
            JPEG, PNG, or WebP
          </p>
          <label htmlFor="file-picker" className="choose-file-button">
            Choose File
            <input
              id="file-picker"
              type="file"
              accept="image/jpeg, image/png, image/webp"
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
          </label>
        </div>

        {/* Generate Button */}
        <GenerateButton
          initialBgColor={previewFile ? '#AD0000' : '#4A4A4A'}
          hoverBgColor={previewFile ? '#DE0000' : '#444444'}
          onClick={handleSubmit} // Trigger backend upload
        >
          {isUploading ? 'Processing...' : 'Generate'}
        </GenerateButton>

        {error && (
          <p
            className="nazi-text mt-2"
            style={{ color: '#fff', fontSize: '18px' }}
          >
            {error}
          </p>
        )}
      </div>
    </div>
  );
};

export default Main;
