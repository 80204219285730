import React, { useState } from 'react';
import './GenerateButton.css';
import Icon from '../../images/plus-2.svg';

const GenerateButton = ({
  children,
  initialBgColor = '#ad0000', // Default initial background color
  hoverBgColor = '#de0000', // Default hover background color
  onClick, // Function to handle button click
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Loading state

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => {
    setIsHovered(false);
    if (!isLoading) setIsClicked(false);
  };
  const handleMouseDown = () => setIsClicked(true);
  const handleMouseUp = () => {
    if (!isLoading) setIsClicked(false);
  };

  const handleClick = async () => {
    if (onClick && typeof onClick === 'function') {
      setIsLoading(true);
      setIsClicked(false);
      try {
        await onClick(); // Call the provided onClick function
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <button
      className={`custom-button ${isClicked ? 'clicked' : ''} ${isLoading ? 'loading' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onClick={handleClick} // Bind the click handler
      style={{
        background: isHovered ? hoverBgColor : initialBgColor,
        border: isHovered || isClicked ? '1px solid #FFF' : 'none',
        alignSelf: 'stretch',
        justifyContent: 'space-between',
        display: 'flex',
      }}
    >
      <div className="text-box">
        <p className="button-text">{children}</p>
      </div>
      <img
        src={Icon}
        alt="Icon"
        className={`button-icon ${isLoading ? 'loading' : isHovered ? 'hovered' : ''}`}
      />
    </button>
  );
};

export default GenerateButton;
